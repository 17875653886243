import {validate as isValidUUID} from "uuid";

const SESSION_ID = 'session-id';
const APP_GUID = 'app-guid';
const VISITOR_ID = 'visitor-id';

export const handleSessionId = (resp) => {
  if (resp && resp.data && resp.data.guid) {
    setAppGuidCookie(resp.data.guid);
  }
}

export const getCookie = (cookieName) => {
  let name = cookieName + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      let _c= c.substring(name.length, c.length);
      if (_c === 'false') {
        return false;
      }
      return _c;
    }
  }
  return null;
}

export const setCookie = (cookieName, cookieValue) => {
  const d = new Date();
  d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
}

export const setAppSessionCookie = (sessionId) => {
  const d = new Date();
  d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
  let expires = 'expires=' + d.toUTCString();
  document.cookie = SESSION_ID + "=" + sessionId + ";" + expires + ";path=/";
}

export const getAppSessionCookie = () => {
  let name = SESSION_ID + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

export const setAppGuidCookie = (guid) => {
  if (!isValidUUID(guid)) {
    return;
  }
  const d = new Date();
  d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
  let expires = 'expires=' + d.toUTCString();
  document.cookie = APP_GUID + "=" + guid + ";" + expires + ";path=/";
}

export const getAppGuidCookie = () => {
  let name = APP_GUID + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return '&appGuid=' + c.substring(name.length, c.length);
    }
  }
  return '';
}

export const getAppSessionCookieHeader = () => {
  let sessionId = getAppSessionCookie();
  if (sessionId) {
    return ';jsessionid=' + sessionId;
  }
  return '';
}