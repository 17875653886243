import {useContext, useEffect, useMemo, useState} from "react";
import {AppContext} from "../../lib/AppContext";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {getInventoryEvents} from "../common/api/InventoryApi";
import {formatDateMask} from "../common/Utils";
import Grid from "@mui/material/Grid";
import {useNavigate} from "react-router-dom";
import MDTypography from "../../components/MDTypography";
import {Card, CardContent} from "@mui/material";

export default function CalendarPane(props) {

  const navigate = useNavigate();

  const [context, setContext] = useContext(AppContext);
  const [eventDate, setEventDate] = useState(null);
  const [eventsLoading, setEventsLoading] = useState(true);
  const [inventoryRender, setInventoryRender] = useState(1);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [specialEvents, setSpecialEvents] = useState(props?.specialEvents);

  useEffect(() => {
    setSpecialEvents(props?.specialEvents);
  }, [props?.specialEvents]);

  const dateClick = function (info) {
    navigate(`/sales/create/event?initDate=${info.dateStr}`);
  }

  const eventClick = function (info) {
    navigate(`/event/view/${info.event?.extendedProps?.guid}`);
  }

  const toolbar = {
    left: 'prev,next',
    center: 'title',
    right: 'timeGridDay,dayGridWeek,dayGridMonth' // user can switch between the two
  };

  useEffect(() => {
    let d = new Date();
    d.setDate(1);
    setEventDate(d);
  }, []);


  const getInventoryEventsFn = async (req, client) => {
    setEventsLoading(true);
    let result = await getInventoryEvents(req, inventoryRender);
    let _events = [];

    for (let i = 0; i < result?.events?.length; i++) {
      let event = result.events[i];
      let endDate = new Date(event.eventDate);
      endDate.setTime(endDate.getTime() + (event.eventDuration * 1000 * 60));

      let _e = {
        guid: event.guid,
        title: event.name,
        start: formatDateMask(event.eventDate, 'YYYY-MM-DD HH:mm:ss'),
        end: formatDateMask(endDate, 'YYYY-MM-DD'),
        displayTime: formatDateMask(event.eventDate, 'hh:mm a'),
        bookedFull: event.bookedFull,
        specialEvent : event?.eventType?.specialEvent
      }
      _events.push(_e);
    }

    setEvents(_events);
    setEventsLoading(false);
    setInventoryRender(result?.inventoryRender);
    setLoading(false);
  }

  const handleDatesSet = (info) => {
    let startDate = new Date(info.start);
    startDate.setHours(0, 0, 0, 0);
    let endDate = new Date(info.end);

    let req = {
      start: startDate,
      end: endDate,
      specialEvents: specialEvents
    }
    getInventoryEventsFn(req, null);
  }

  function renderEventContent(eventInfo) {
    let bookedFull = eventInfo?.event?.extendedProps?.bookedFull;
    return (
      <Card className={(bookedFull ? 'bg-info' : 'bg-primary') + " w-100 "}>
        <CardContent>
          <MDTypography variant="p" color="white" fontWeight="bold">
            {eventInfo.event?.title}
          </MDTypography>
          <br/>
          <MDTypography variant="p" color="white" fontWeight="regular">
            {bookedFull && <><span className="fa fa-circle-check"></span>&nbsp;</>}
              {eventInfo.event?.extendedProps?.displayTime}
            </MDTypography>
        </CardContent>
      </Card>
    )
  }

  return (
    <div>
      <h5>Calendar</h5>
      <Grid container spacing={3}>
        <Grid item xs={12} xl={12} sx={{height: "max-content"}}>
          {useMemo(
            () => (<FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView='dayGridMonth'
              weekends={true}
              eventContent={renderEventContent}
              datesSet={handleDatesSet}
              events={events}
              headerToolbar={toolbar}
              dateClick={dateClick}
              eventClick={eventClick}
              // eventContent={renderEventContent}
            />), [events]
          )}
        </Grid>
      </Grid>
    </div>
  )
}
