import {useLocation, useNavigate} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import {createRef, useContext, useEffect, useState} from "react";
import {Card, CardContent} from "@mui/material";
import Meta from "../common/Meta";
import moment from "moment";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import Footer from "../Footer";
import MDButton from "../../components/MDButton";
import CalendarPane from "../dashboard/CalendarPane";
import EventsMiniDataTable from "../client/pane/EventsMiniDataTable";
import {getCookie, setCookie} from "../common/appCookie";

export default function Events(props) {

  const location = useLocation();

  const navigate = useNavigate();

  const [specialEvents, setSpecialEvents] = useState(props?.specialEvents);

  useEffect(()=> {
    setSpecialEvents(props?.specialEvents);
  }, [props?.specialEvents]);

  const calendarRef = createRef();
  const TIME_GRID_DAY = 'timeGridDay';

  const [context, setContext] = useContext(AppContext);
  const [initialLoad, setInitialLoad] = useState(true);

  let startDate = new Date();
  startDate.setDate(1);
  startDate.setHours(0, 0, 0, 0);
  let endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 1);

  const DEFAULT_INVENTORY_REQUEST = {
    start: startDate,
    end: endDate
  }

  const [eventRequest, setEventRequest] = useState(DEFAULT_INVENTORY_REQUEST);
  const [eventView, setEventView] = useState(TIME_GRID_DAY);

  const getCalendarView = () => {
    let calview = getCookie('calendarView');
  }
  const [calendarView, setCalendarView] = useState(getCookie('calendarView') || false);
  const toggleMonthView = () => {
    setCookie('calendarView', !calendarView);
    setCalendarView(!calendarView);
  }

  const addInventoryEventFn = (eventDate) => {
    let formattedDate = '';
    if (eventDate) {
      formattedDate = '?initDate=' + moment(eventDate).format('YYYY-MM-DD HH:mm Z');
    }
    navigate('/sales/create/event' + formattedDate);
  }

  return (

    <DashboardLayout>
      <Meta pageTitle={( specialEvents ? 'Special ' : '') + 'Events'}/>
      <ContextSearchNavbar title={( specialEvents ? 'Special ' : '') + 'Events'}/>
      <MDBox py={3}>
        <MDBox mb={3}>
          <Card>
            <CardContent>

              <MDButton
                variant="gradient"
                size="small"
                color="info"
                className={'btn btn-sm btn-secondary pull-right'}
                onClick={(e) => {
                  e.preventDefault();
                  addInventoryEventFn();
                }}>
                <span className={'fa fa-plus'}></span>&nbsp;Add New Event
              </MDButton>

              <MDButton
                size="small"
                color="primary"
                className={'btn btn-sm btn-secondary pull-right mr-2'}
                onClick={(e) => {
                  toggleMonthView();
                }}>
                <span className={'fa fa-calendar'}></span>&nbsp;{calendarView ? 'Grid' : 'Calendar'} View
              </MDButton>

              {specialEvents && <>
                {!calendarView && <EventsMiniDataTable specialEvents={true}/>}
                {calendarView && <CalendarPane specialEvents={true}/>}
              </>}
              {!specialEvents && <>
                {!calendarView && <EventsMiniDataTable specialEvents={false}/>}
                {calendarView && <CalendarPane specialEvents={false}/>}
              </>}


            </CardContent>
          </Card>
        </MDBox>
      </MDBox>

      <Footer mt={5}/>

    </DashboardLayout>
  );
}