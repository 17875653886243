import {Col, Row} from "react-bootstrap";
import {useNavigate, useSearchParams} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {
  getAgeRangeTypes,
  getEventLocations,
  getInventoryEvent,
  getInventoryEventTypes,
  getInventoryTypes,
  saveInventoryEvent
} from "../common/api/InventoryApi";
import {addDate, handleDefaultValues} from "../common/Utils";
import {Controller, useForm} from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import MDTypography from "../../components/MDTypography";
import Divider from "@mui/material/Divider";
import MDButton from "../../components/MDButton";
import MDBox from "../../components/MDBox";
import MDInput from "../../components/MDInput";
import {Chip, NativeSelect} from "@mui/material";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Loading from "../common/Loading";

export default function ManageInventoryEventForm(props) {

  const navigate = useNavigate();

  const [context, setContext] = useContext(AppContext);
  const [newEvent, setNewEvent] = useState(props?.inventory?.guid ? false : true);

  const [inventoryTypes, setInventoryTypes] = useState([]);
  const [editReady, setEditReady] = useState(props?.inventory?.sessionEvent ? false : true);

  const [eventLocations, setEventLocations] = useState([]);
  const [inventoryEventTypes, setInventoryEventTypes] = useState([]);
  const [ageRangeTypes, setAgeRangeTypes] = useState([]);
  const [inventoryType, setInventoryType] = useState(null);
  const [renderDate, setRenderDate] = useState(new Date().getTime());
  const [manageRecurring, setManageRecurring] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [eventDate, setEventDate] = useState(new Date());
  const [eventEndDate, setEventEndDate] = useState(props.inventory?.eventEndDate || addDate(new Date(), 1));

  const [sessionGuid, setSessionGuid] = useState(props?.inventory?.sessionEvent ? props?.inventory?.parentEventGuid : null);
  const [sessionEvent, setSessionEvent] = useState(props?.inventory?.sessionEvent);
  const [createSessionEventInSeries, setCreateSessionEventInSeries] = useState(props?.inventory?.createSessionEventInSeries);

  useEffect(() => {
    setCreateSessionEventInSeries(props?.inventory?.createSessionEventInSeries);
  }, [props?.inventory]);

  useEffect(() => {
    if (sessionGuid) {
      setEditReady(false);
      getInventoryEvent(sessionGuid).then(result => {
        if (result.error) {
          setContext({...context, error: {message: result.message}});
        } else {
          let e = {...result};
          e.eventDate = new Date(e.eventDate);
          e.eventEndDate = new Date(e.eventEndDate);
          e.parentEventGuid = sessionGuid;
          if (props?.inventory?.createSessionEventInSeries) {
            e.guid = null;
            e.id = null;
          }
          setEditReady(true);
          setInventory({...e});
        }
      });
    }
  }, [sessionGuid]);

  useEffect(() => {
    let _d = new Date();
    if (props.inventory?.eventDate) {
      _d = new Date(props.inventory.eventDate);
    } else if (searchParams?.get('initDate')) {
      let ds = searchParams?.get('initDate').replaceAll('%20', ' ');
      _d = moment(ds, 'YYYY-MM-DD HH:mm Z').toDate();
    }
    let m = (Math.round(_d.getMinutes() / 5) * 5) % 60;
    _d.setMinutes(m);
    setEventDate(_d);
    setEventDateTime(_d);
    setEventEndDate(addDate(_d, 1));
  }, []);

  useEffect(() => {
    if (!props) {
      return;
    }
    setManageRecurring(props?.manageRecurring);
  }, [props])

  const [eventDateTime, setEventDateTime] = useState(props.inventory?.eventDate || new Date());
  const [eventDuration, setEventDuration] = useState(props.inventory?.eventDuration || 60);

  useEffect(() => {
    let d = new Date(eventEndDate);
    d.setHours(eventDateTime.getHours());
    d.setMinutes(eventDateTime.getMinutes());
    setEventEndDate(d);

  }, [eventDateTime])

  const [saving, setSaving] = useState(false);
  const [manageInventoryType, setManageInventoryType] = useState(false);

  const [manageInventory, setManageInventory] = useState(false);
  const [inventories, setInventories] = useState(null);
  const [sortInventory, setSortInventory] = useState({field: 'id', direction: 'asc'});
  const [isNew, setIsNew] = useState(!!!props.inventory?.guid);

  const [inventory, setInventory] = useState(props.inventory);
  const [isInventoryEvent, setIsInventoryEvent] = useState(props.isInventoryEvent);

  const [inventoryLabel, setInventoryLabel] = useState(props.isInventoryEvent ? 'Event' : 'Inventory');

  const [inventoryImage, setInventoryImage] = useState(null);
  const [inventoryImageName, setInventoryImageName] = useState(null);
  const [inventoryImageType, setInventoryImageType] = useState(null);

  const [preview, setPreview] = useState(null);

  const [formInvalid, setFormInvalid] = useState(false);
  const [typesReady, setTypesReady] = useState(false);
  const [recurringEvent, setRecurringEvent] = useState(props.inventory?.recurringEvent || false);
  const [allDayEvent, setAllDayEvent] = useState(props.inventory?.allDay || false);

  const initialCronExpression = '0 0 09 ? * MON-FRI *';

  const [cronExpression, setCronExpression] = useState(null);

  const RECURRENCES_DAILY = 1;
  const RECURRENCES_WEEKLY = 2;
  const RECURRENCES_DAYS_OF_MONTH = 3;

  const [recurrenceType, setRecurrenceType] = useState(RECURRENCES_DAILY);
  const [recurrencesDaily, setRecurrencesDaily] = useState(1);
  const [sessionEventOccurrences, setSessionEventOccurrences] = useState(1);

  const [recurrencesGap, setRecurrencesGap] = useState(0);

  const daysInWeek = [
    {id: 1, name: 'Sunday'},
    {id: 2, name: 'Monday'},
    {id: 3, name: 'Tuesday'},
    {id: 4, name: 'Wednesday'},
    {id: 5, name: 'Thursday'},
    {id: 6, name: 'Friday'},
    {id: 7, name: 'Saturday'}
  ];

  const daysInMonth = [];
  for (let i = 1; i <= 31; i++) {
    daysInMonth.push({id: i, name: i});
  }

  useEffect(() => {
    setTypesReady(inventoryTypes?.length && inventoryEventTypes?.length && ageRangeTypes?.length);
  }, [inventoryTypes, inventoryEventTypes, ageRangeTypes]);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(inventory)
  });

  const handleSave = async (data) => {

    setSaving(true);

    let d = {...data};

    d.inventoryImage = inventoryImage;
    d.inventoryImageName = inventoryImageName;
    d.inventoryImageType = inventoryImageType;

    let _eventDate = new Date(eventDate);
    _eventDate.setHours(eventDateTime.getHours());
    _eventDate.setMinutes(eventDateTime.getMinutes());
    d.duration = eventDuration;
    d.eventEndDate = eventEndDate;

    d.eventDate = _eventDate;
    d.cronExpression = cronExpression;
    d.inventoryEvent = true;
    d.recurrenceType = recurrenceType;
    d.dayOfWeek = dayOfWeek;
    d.dayOfMonth = dayOfMonth;
    d.manageRecurring = manageRecurring;
    let _recurrenceTimes = [];
    d.sessionEvent = sessionEvent;

    for (let i = 0; i < recurrenceTimes.length; i++) {
      let rd = new Date(recurrenceTimes[i]);
      rd.setDate(eventDate.getDate());
      rd.setMonth(eventDate.getMonth());
      rd.setMilliseconds(0);
      rd.setFullYear(eventDate.getFullYear());
      _recurrenceTimes.push(rd);
    }
    d.recurrenceTimes = [..._recurrenceTimes];

    if (d.sessionEvent) {
      let _sessions = [];
      for (let i = 0; i < sessionTimes.length; i++) {
        if (i === 0) {
          d.eventDate = sessionTimes[i];
        } else {
          _sessions.push(sessionTimes[i]);
        }
      }
      d.sessionTimes = [..._sessions];
    }

    let result = await handleSaveInventoryEvent(d);

    if (!result.error) {
      if (props?.handleSave) {
        props.handleSave(result);
      } else {
        window.location = '/event/view/' + result.guid + '?initDate=' + _eventDate.toISOString();
      }
    } else {
      setContext({...context, error: {message: result.message}});
      setSaving(false);
    }

  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setPreview(null);

      setInventoryImageName(null);
      setInventoryImageType(null);
      setInventoryImage(null);
      return;
    }
    setInventoryImageName(file.name);
    setInventoryImageType(file.type);

    const reader = new FileReader();

    reader.onload = function (evt) {
      setInventoryImage(btoa(evt.target.result));
    }
    reader.readAsBinaryString(file);

    setPreview(URL.createObjectURL(file));

    let _inv = {...inventory};
    _inv.removeImage = false;
    setInventory(_inv);
  }

  const toggleSessionEvent = (e) => {
    setRecurringEvent(false);
    setSessionEvent(e.target.checked);
  };

  const toggleRecurringEvent = (e) => {
    setRecurringEvent(e.target.checked);
    setSessionEvent(false);
    if (!recurringEvent) {
      setCronExpression(null);
    }
  };

  const toggleAllDay = (e) => {
    setAllDayEvent(e.target.checked);
    if (e.target.checked) {
      setEventDuration(0);
    }
  };

  const loadInventoryTypes = () => {
    getInventoryTypes().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setInventoryTypes(result);
      }
    });
  };

  const defaultLocation = {id: -1, name: 'Select Location'};

  const loadEventLocations = () => {
    getEventLocations().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        let _locations = result;
        _locations.unshift(defaultLocation);
        setEventLocations(_locations);
      }
    });
  };

  const loadInventoryEventTypes = () => {
    getInventoryEventTypes().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setInventoryEventTypes(result);
      }
    });
  }

  const loadAgeRangeTypes = () => {
    getAgeRangeTypes().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setAgeRangeTypes(result);
      }
    });
  }

  const handleSaveInventoryEvent = async (inventory) => {
    let result = await saveInventoryEvent(inventory);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    }
    return result;
  }

  const handleCloseInventory = () => {
    setManageInventory(false);
  }

  useEffect(() => {
    // get inventory types
    loadInventoryTypes();
    loadInventoryEventTypes();
    loadAgeRangeTypes();
    loadEventLocations();
  }, []);

  const updateEventDates = (date) => {
    setEventDate(date);
    setEventEndDate(addDate(date, 1));
  }

  const [dayOfWeek, setDayOfWeek] = useState([]);
  const [dayOfMonth, setDayOfMonth] = useState([]);

  const handleWeekdayChange = (e) => {
    let _days = [...dayOfWeek];
    if (e.target.checked) {
      _days = [..._days, parseInt(e.target.value, 10)];
    } else {
      _days.splice(dayOfWeek.indexOf(parseInt(e.target.value, 10)), 1);
    }
    setDayOfWeek(_days);
  };

  const handleMonthdayChange = (e) => {
    let _days = [...dayOfMonth];
    if (e.target.checked) {
      _days = [..._days, parseInt(e.target.value, 10)];
    } else {
      _days.splice(dayOfMonth.indexOf(parseInt(e.target.value, 10)), 1);
    }
    setDayOfMonth(_days);
  };

  const recurringStyle = {
    'width': '300px',
  }

  const toggleDeposit = (e) => {
    let _inventory = {...inventory};
    _inventory.paymentDeposit = e.target.checked;
    if (!e.target.checked) {
      _inventory.depositPercent = 0;
    }
    setInventory(_inventory);
  }

  const [recurrenceTimes, setRecurrenceTimes] = useState([]);
  const [sessionTimes, setSessionTimes] = useState([]);

  const updateRecurrenceTimes = (eventDateTime, idx) => {
    let _times = [...recurrenceTimes];
    _times[idx] = eventDateTime;
    setRecurrenceTimes(_times);
    setRenderIdx(renderIdx + 1);
  }

  const updateSessionTimes = (eventDateTime, idx) => {
    let _times = [...sessionTimes];
    _times[idx] = eventDateTime;
    setSessionTimes(_times);
    setRenderIdx(renderIdx + 1);
  }

  const categoryChange = (categoryId) => {
    let showHelp = false;
    for (let j in inventoryEventTypes) {
      if (parseInt(inventoryEventTypes[j].id, 10) === parseInt(categoryId, 10)) {
        showHelp = inventoryEventTypes[j].initialDeposit;
      }
    }
    setShowDepositHelpText(showHelp);
  }

  const [showDepositHelpText, setShowDepositHelpText] = useState(false);

  const eventLabels = (idx) => {
    if (idx === 0) {
      return 'first';
    }
    if (idx === 1) {
      return 'second';
    }
    if (idx === 2) {
      return 'third';
    }
    if (idx === 3) {
      return 'fourth';
    }
    if (idx === 4) {
      return 'fifth';
    }
    if (idx === 5) {
      return 'sixth';
    }
    if (idx === 6) {
      return 'seventh';
    }
    if (idx === 7) {
      return 'eighth';
    }
    if (idx === 8) {
      return 'ninth';
    }
    if (idx === 9) {
      return 'tenth';
    }
    return 'event ' + (idx + 1);
  }

  const recurrenceTimePicker = (idx) => {
    return (<span key={idx}>
      <label>Choose time for {eventLabels(idx)} event</label>
      <Controller
        className={'d-block form-control'}
        control={control}
        name={eventDate + '-' + idx}
        id={eventDate + '-' + idx}
        /*
        TODO set min and max times based on COMPANY PREFERENCES
         */
        render={({field: {onChange, onBlur, value, ref}}) => (
          <ReactDatePicker
            required={true}
            portalId="portal-id=`${idx}`"
            calendarClassName="psb-form-control"
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            className={'form-control'}
            onChange={(date) => updateRecurrenceTimes(date, idx)}
            onBlur={onBlur}
            selected={recurrenceTimes[idx]}
          />
        )}
      />
      </span>)
  }

  const sessionDateTimePicker = (idx) => {
    return (<div key={idx}>
      <label>Choose time for {eventLabels(idx)} session event</label>
      <Controller
        className={'d-block form-control'}
        control={control}
        name={eventDate + '-' + idx}
        id={eventDate + '-' + idx}
        /*
        TODO set min and max times based on COMPANY PREFERENCES
         */
        render={({field: {onChange, onBlur, value, ref}}) => (
          <ReactDatePicker
            portalId="session-portal-id=`${idx}`"
            required={true}
            showTimeSelect
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="MMMM d, yyyy h:mm aa"
            className={'form-control'}
            onChange={(date) => updateSessionTimes(date, idx)}
            onBlur={onBlur}
            selected={sessionTimes[idx]}
          />
        )}
      />
    </div>)
  }

  const [renderIdx, setRenderIdx] = useState(1);

  const renderOcccurrencesInput = () => {
    let inputs = [];
    for (let i = 0; i < recurrencesDaily; i++) {
      inputs.push(recurrenceTimePicker(i));
    }
    return inputs;
  }

  const renderSessionsInput = () => {
    let inputs = [];
    for (let i = 0; i < sessionEventOccurrences; i++) {
      inputs.push(sessionDateTimePicker(i));
    }
    return inputs;
  }

  return (

    <>
      {!editReady && <Loading/>}
      {editReady && <form onSubmit={handleSubmit(handleSave)} id={'inventoryForm'}>
        {sessionEvent && !createSessionEventInSeries &&
          <MDBox className={'px-4'}>
            <MDTypography variant="p" color="primary" fontWeight="regular">
              <Icon>event_repeat</Icon> You are managing a session event
            </MDTypography>
          </MDBox>
        }
        <MDBox className={'p-4'}>
          <Row>
            {!manageRecurring &&
              <Col className={'col col-4'} style={manageRecurring ? {} : recurringStyle}>

                {!sessionEvent && <> <Controller
                  className={'d-block'}
                  control={control}
                  name="eventDate"
                  id="eventDate"
                  /*
                  TODO set min and max times based on COMPANY PREFERENCES
                   */
                  render={({field: {onChange, onBlur, value, ref}}) => (
                    <ReactDatePicker
                      todayButton="Today"
                      peekNextMonth
                      // minTime={setHours(setMinutes(new Date(), 0), 9)}
                      // maxTime={setHours(setMinutes(new Date(), 15), 17)}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      calendarClassName="psb-form-control"
                      // showTimeSelect
                      dateFormat="MMMM d, yyyy h:mm aa"
                      className={'form-control'}
                      onChange={(date) => updateEventDates(date)}
                      onBlur={onBlur}
                      inline
                      selected={eventDate}
                    />
                  )}
                />
                  <span className={'d-block my-2'}></span>
                </>
                }

                {!recurringEvent && !sessionEvent && <Controller
                  className={'d-block my-2'}
                  control={control}
                  name="eventDateTime"
                  id="eventDateTime"
                  render={({field: {onChange, onBlur, value, ref}}) => (
                    <ReactDatePicker
                      showTimeSelect
                      showTimeSelectOnly
                      calendarClassName="psb-form-control"
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      className={'form-control'}
                      onChange={(date) => setEventDateTime(date)}
                      onBlur={onBlur}
                      selected={eventDateTime}
                    />
                  )}
                />}

                <div className={sessionEvent ? 'd-block' : 'd-block my-2'}>
                  <label className={'form-check-label cursor-pointer'} htmlFor="allDay">
                    <Switch
                      style={{marginRight: '5px'}}
                      role={'switch'}
                      {...register('allDayToggle',
                        {
                          onChange: (e) => {
                            toggleAllDay(e);
                          }
                        })}
                      id="allDay"/>
                    &nbsp;All Day
                  </label>
                </div>

                <span className={'d-block my-2 ' + (allDayEvent ? 'd-none' : '')}>
                    <label htmlFor="eventDuration">
                      Duration (minutes)
                    </label>
                  <input type="number"
                         required
                         disabled={allDayEvent}
                         autoComplete="off"
                         className={'form-control'}
                         defaultValue={eventDuration}
                         placeholder={'Event Duration'}
                         {...register('eventDuration',
                           {
                             valueAsNumber: true,
                             onChange: (e) => {
                               setEventDuration(e.target.value);
                             }
                           })}
                         id="eventDuration"/>
                  <p className={'py-2 fw-light'}>
                    Length of event in minutes
                  </p>
              </span>

                <Divider/>

                {newEvent && !createSessionEventInSeries && sessionEvent && <>

                  <label htmlFor={'sessionEventTimes'}>Number of Events in Session</label>

                  <input type="number"
                         required
                         autoComplete="off"
                         className={'form-control'}
                         min="1"
                         defaultValue={sessionEventOccurrences}
                         placeholder={'Session events'}
                         {...register('sessionEventTimes',
                           {
                             valueAsNumber: true,
                             onChange: (e) => {
                               setSessionEventOccurrences(e.target.value);
                             }
                           })}
                         id="sessionEventTimes"/>
                  <p className={'mt-2 fw-medium'}>
                    Enter Session Event Dates below.
                  </p>

                  {sessionEvent && sessionEventOccurrences > 0 && <>
                    {renderIdx &&
                      <>
                        {renderSessionsInput()}
                      </>
                    }
                  </>
                  }
                </>
                }

                {newEvent && recurringEvent &&
                  <>
                    <div className="form-group">
                      <label htmlFor={'eventEndDate'}>Repeat Until</label>
                      <div className={'input-group'}>
                        {eventEndDate && <Controller
                          className={'d-block form-control'}
                          control={control}
                          name="eventDate"
                          id="eventDate"
                          /*
                          TODO set min and max times based on COMPANY PREFERENCES
                           */
                          render={({field: {onChange, onBlur, value, ref}}) => (
                            <ReactDatePicker
                              todayButton="Today"
                              peekNextMonth
                              // minTime={setHours(setMinutes(eventDate, 0), 9)}
                              // maxTime={setHours(setMinutes(eventDate, 15), 17)}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              calendarClassName="psb-form-control"
                              // showTimeSelect
                              dateFormat="MMMM d, yyyy"
                              className={'form-control'}
                              onChange={(date) => setEventEndDate(date)}
                              onBlur={onBlur}
                              selected={eventEndDate || addDate(new Date(), 1)}
                            />
                          )}
                        />}
                      </div>
                      <p className={'py-2 fw-light'}>Last day of auto-scheduled events</p>
                    </div>

                    <Divider/>
                    <p className={'mt-1 fw-medium'}>Repeat event options</p>
                    <label className={'d-block'}>
                      <input type={'radio'}
                             defaultChecked
                             name={'recurrenceType'}
                             value={RECURRENCES_DAILY}
                             onChange={(e) => {
                               setRecurrenceType(RECURRENCES_DAILY);
                             }}
                      /> Repeat Daily</label>

                    <label className={'d-block'}>
                      <input type={'radio'}
                             name={'recurrenceType'}
                             value={RECURRENCES_WEEKLY}
                             onChange={(e) => {
                               setRecurrenceType(RECURRENCES_WEEKLY);
                             }}/> Repeat Weekly
                    </label>
                    <label className={'d-block'}>
                      <input type={'radio'}
                             name={'recurrenceType'}
                             value={RECURRENCES_DAYS_OF_MONTH}
                             onChange={(e) => {
                               setRecurrenceType(RECURRENCES_DAYS_OF_MONTH);
                             }}
                      /> Repeat Monthly
                    </label>

                    {recurrenceType &&
                      <>
                        <Divider/>
                        <label htmlFor={'recurrencesDaily'}>Events per day</label>
                        <input type="number"
                               required
                               min="1"
                               autoComplete="off"
                               className={'form-control'}
                               defaultValue={recurrencesDaily}
                               placeholder={'Events per day'}
                               {...register('recurrencesDaily',
                                 {
                                   valueAsNumber: true,
                                   onChange: (e) => {
                                     setRecurrencesDaily(e.target.value);
                                   }
                                 })}
                               id="recurrencesDaily"/>
                        <p className={'mt-2 fw-medium'}>
                          Event occurrences per day, choose times of event(s) below
                        </p>

                        {recurrencesDaily > 0 && <>
                          {renderIdx &&
                            <>
                              {renderOcccurrencesInput()}
                            </>
                          }
                        </>
                        }
                      </>
                    }
                    {recurrenceType === RECURRENCES_WEEKLY && <>
                      <Divider/>
                      <p className={'py-2 fw-light'}>
                        Choose day(s) of week for event to repeat.
                      </p>
                      {daysInWeek.map((day) =>
                        <label className={'d-block'} htmlFor={day.id} key={'weekDay-' + day.id}>
                          <input type={'checkbox'}
                                 checked={dayOfWeek.includes(parseInt(day.id, 10))}
                                 id={day.id} value={day.id} onChange={(e) => {
                            handleWeekdayChange(e);
                          }}/> &nbsp;{day.name}
                        </label>
                      )}
                    </>}

                    {recurrenceType === RECURRENCES_DAYS_OF_MONTH && <span className={'my-n2'}>
                    <p className={'mt-2 fw-medium'}>
                              Schedule days(s) of month to repeat event
                            </p>
                      {daysInMonth.map((day) =>
                        <>
                          <label className={'d-inline p-1'} htmlFor={day.id} key={'monthDay-' + day.id}>
                            <input type={'checkbox'}
                                   checked={dayOfMonth.includes(parseInt(day.id, 10))}
                                   id={day.id} value={day.id} onChange={(e) => {
                              handleMonthdayChange(e);
                            }}/> &nbsp;{day.name}
                          </label>
                          {(day.id) % 5 === 0 && <br/>}
                        </>
                      )}
                      </span>}
                  </>
                }

              </Col>
            }
            <Col className={'d-block'}>
              <div>

                <Row>
                  <Col className={'col'} md={6} sm={12}>
                    <div className="form-group">
                      <label htmlFor="name">{inventoryLabel} Name</label>
                      <MDInput type="text" required
                               {...register('name')}
                               autoComplete="off"
                               className="form-control" id="name"
                               placeholder={"Enter " + inventoryLabel + " Name"}/>
                    </div>
                  </Col>
                  <Col className={'col'} md={6} sm={12}>
                    <div className="form-group">
                      <label htmlFor="name">{sessionEvent ? 'Session ' : ''} Price</label>
                      <div className={'input-group'}>
                        <span className="input-group-text">
                          <span className={'fa fa-dollar'}></span>
                        </span>
                        <input type="number" step="0.01" required
                               {...register('price', {
                                 valueAsNumber: true,
                               })}
                               disabled={createSessionEventInSeries}
                               defaultValue={'0.00'}
                               autoComplete="off"
                               className="form-control" id="price"
                               placeholder="Price"/>
                      </div>

                    </div>
                  </Col>
                </Row>

                <Row className={'mt-2'}>

                  <Col className={'col'} md={6} sm={12}>
                    <div className="form-group">
                      <label htmlFor="inventoryType">Event Location</label>
                      {eventLocations?.length > 0 && <NativeSelect
                        variant="outlined"
                        id={'eventLocation'} name={'eventLocation'} className={'form-control'}
                        {...register('eventLocation.id', {
                          valueAsNumber: true
                        })} >
                        {
                          eventLocations.sort((a, b) => a.id - b.id).map((c) =>
                            <option value={c.id} key={c.id}>
                              {c.name}
                            </option>)
                        }
                      </NativeSelect>}
                    </div>
                  </Col>

                  <Col className={'col'} md={6} sm={12}>
                    <div className="form-group">
                      <label htmlFor="inventoryType">Inventory Type</label>
                      {inventoryTypes?.length > 0 && <NativeSelect
                        variant="outlined"
                        onChange={(e) => {
                          console.dir(e);
                        }}
                        disabled={createSessionEventInSeries}
                        id={'inventoryType'} name={'inventoryType'} className={'form-control'}
                        {...register('inventoryType.id', {
                          valueAsNumber: true
                        })} >
                        {
                          inventoryTypes.map((c) =>
                            <option value={c.id} key={c.id}>
                              {c.name}
                            </option>)
                        }
                      </NativeSelect>}
                    </div>
                  </Col>
                  <Col className={'col mt-2'} md={6} sm={12}>
                    <div className="form-group">
                      <label htmlFor="inventoryImage">{inventoryLabel} Image</label>
                      <input type="file"
                             accept="image/*"
                             {...register('inventoryImage', {
                               onChange: (e) => {
                                 handleFileChange(e);
                               }
                             })}
                             autoComplete="off"
                             className="form-control" id="inventoryImage"/>

                      {preview && <><img className={'mt-2 img-thumbnail'} src={preview} alt="img"/>
                        <div>
                          <Chip label="Remove"
                                className={'cls clear-both'}
                                variant="outlined" onDelete={() => {
                            let _inv = {...inventory}
                            _inv.imageUrl = null;
                            _inv.removeImage = true;
                            setInventory(_inv);
                            setPreview(null);
                          }}/>
                        </div>
                      </>}
                      {!preview && inventory?.imageUrl &&
                        <><img className={'mt-2 img-thumbnail'} src={inventory.imageUrl} alt="img"/>
                          <div>
                            <Chip label="Remove"
                                  className={'cls clear-both'}
                                  variant="outlined" onDelete={() => {
                              let _inv = {...inventory}
                              _inv.imageUrl = null;
                              _inv.removeImage = true;
                              setInventory(_inv);
                              setPreview(null);
                            }}/>
                          </div>
                        </>}
                    </div>
                  </Col>
                  <Col className={'col mt-2'} md={6} sm={12}>
                    <div className="form-group">
                      <label htmlFor="totalAvailable">{isInventoryEvent ? 'Attendees' : 'Quantity'}</label>
                      <input type="number" required
                             {...register('totalAvailable', {
                               valueAsNumber: true,
                             })}
                             defaultValue={1}
                             autoComplete="off"
                             className="form-control" id="totalAvailable"
                      />
                    </div>
                  </Col>
                </Row>


                <Row className={'mt-2'}>
                  <Col className={'col '} md={6} sm={12}>

                    <div className="form-group mt-2">
                      <label htmlFor="eventType">Event Category</label>
                      {inventoryEventTypes?.length > 0 && <NativeSelect
                        variant="outlined"
                        disabled={createSessionEventInSeries}
                        id={'eventType'} name={'eventType'}

                        className={'form-control'}
                        {...register('eventType.id', {
                          valueAsNumber: true,
                          onChange: (e) => {
                            categoryChange(e.target.value)
                          }
                        })} >
                        {
                          inventoryEventTypes.map((c) =>
                            <option value={c.id} key={c.id}>
                              {c.name}
                            </option>)
                        }
                      </NativeSelect>}
                      {showDepositHelpText &&
                        <p className={'py-2 fw-light'}>Note: This Event Category accepts an initial deposit.
                          Sale Price will be divided by Deposit percentage an an invoice will be generated for
                          the remaining balance.</p>}

                    </div>
                  </Col>
                  <Col className={'col'} md={6} sm={12}>
                    <div className="form-group mt-2">
                      <label htmlFor="ageRange">Age Range</label>
                      {ageRangeTypes?.length > 0 && <NativeSelect
                        variant="outlined"
                        disabled={createSessionEventInSeries}
                        id={'ageRange'} name={'ageRange'} className={'form-control'}
                        {...register('ageRange.id', {
                          valueAsNumber: true,
                        })} defaultValue={-1}>
                        {
                          ageRangeTypes
                            .sort((a, b) => a.id > b.id ? 1 : -1)
                            .map((c) =>
                              <option value={c.id} key={c.id}>
                                {c.name}
                              </option>)
                        }
                      </NativeSelect>}
                    </div>
                  </Col>
                </Row>
                {newEvent &&
                  <Row className={'mt-2'}>

                    <Col className={'col'} md={6} sm={12}>
                      <div className={'d-block my-2'}>
                        <label className={'form-check-label cursor-pointer'} htmlFor="recurringEvent">
                          <Switch
                            disabled={sessionEvent}
                            style={{marginRight: '5px'}}
                            role={'switch'}
                            {...register('recurringEvent',
                              {
                                onChange: (e) => {
                                  toggleRecurringEvent(e);
                                }
                              })}
                            id="recurringEvent"/>
                          &nbsp;Recurring Event
                        </label>
                        <p className={'py-2 fw-light'}>
                          Recurring events occur multiple times on a schedule.
                        </p>
                      </div>
                    </Col>
                    <Col className={'col'} md={6} sm={12}>

                      <div className={'d-block my-2'}>
                        <label className={'form-check-label cursor-pointer'} htmlFor="sessionEvent">
                          <Switch
                            style={{marginRight: '5px'}}
                            role={'switch'}
                            defaultChecked={props?.inventory?.sessionEvent}
                            disabled={createSessionEventInSeries || recurringEvent}
                            {...register('sessionEventToggle',
                              {
                                onChange: (e) => {
                                  toggleSessionEvent(e);
                                }
                              })}
                            id="sessionEvent"/>
                          &nbsp;Session Event
                        </label>
                      </div>
                      <p className={'py-2 fw-light'}>
                        Session events occur in groups, such as a series of classes or a clinic.
                      </p>
                    </Col>
                  </Row>
                }
                <Row className={'mt-2'}>
                  <Col className={'col'} sm={12} xs={12}>
                    <div className="form-group">
                      <label htmlFor="name">{inventoryLabel} Description</label>
                      <textarea type="text"
                                {...register('desc')}
                                autoComplete="off"
                                maxLength={255}
                                className="form-control" id="desc"
                                placeholder="Enter Inventory Description"/>
                    </div>
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  <Col className={'col'} sm={12} xs={12}>
                    <MDButton className={'pull-right'}
                              type="submit"
                              variant="gradient"
                              color="info"
                              disabled={formInvalid || saving}>
                      {saving ? 'Saving...' : 'Save'}
                    </MDButton>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

        </MDBox>
      </form>
      }
    </>
  );
}